export const handleClick = (link: string) => {
  const router = useRouter();
  if (/^(https?:)?\/\//.test(link)) {
    window.location.href = link;
  } else {
    router.push(link);
  }
};

export const handleLinkClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (target.tagName.toLowerCase() === 'a') {
    event.preventDefault();
    const link = (target as HTMLAnchorElement).getAttribute('href');
    if (link) {
      handleClick(link);
    }
  }
};
